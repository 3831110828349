<template>
  <div style="position: relative;">
    <transition name="fade" @enter="enter" @leave="leave" :css="false" mode="out-in">
      <router-view :key="$route.params.id" />
    </transition>
  </div>
</template>
<script>
  import {TweenMax, Power4} from "gsap/all";
  export default {
    name: "magazineDetail",
    methods: {
      enter(el, done) {
        TweenMax.fromTo(
          el,
          0.5,
          {
            opacity: 0
          },
          {
            opacity: 1,
            ease: Power4.easeInOut,
            onComplete: () => {
              done();
            }
          }
        );
      },
      leave(el, done) {
        TweenMax.fromTo(
          el,
          0.5,
          {
            opacity: 1
          },
          {
            opacity: 0,
            ease: Power4.easeInOut,
            onComplete: () => {
              done();
            }
          }
        );
      }
    }
  };
</script>
<style lang="scss" type="text/css">
  .fade-enter-active {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
  }
</style>
